import React, { useState, useEffect } from "react";
import { Box, TextField, MenuItem, Button, Typography, CircularProgress } from '@mui/material'; // Use Material UI components for form styling
import { useForm, Controller, FormProvider } from "react-hook-form";
import api from '../../api/api';

const PolicyForm = ({ onSubmit, selectedPolicy }) => {
    const methods = useForm({
        defaultValues: {
            policyName: "",
            action: "ALLOW",
            description: "",
            condition: {},
        },
    });

    const { reset, handleSubmit, control, watch, formState: { errors, isValid } } = methods;
    const [isLoading, setIsLoading] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);

    // Watch for changes in the form values
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            setIsFormDirty(true); // Set dirty state when any field changes
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (selectedPolicy) {
            reset({
                policyName: selectedPolicy.PolicyName,
                action: selectedPolicy.action,
                description: selectedPolicy.description,
                condition: selectedPolicy.Conditions,
            });
            setIsFormDirty(false); // Reset dirty state
        }
    }, [selectedPolicy, reset]);

    const handleFormSubmit = async (data) => {
        setIsLoading(true);

        const trimmedPolicyName = data.policyName.trim();

        try {
            // Check if the policy name has been modified
            if (!selectedPolicy || trimmedPolicyName !== selectedPolicy.PolicyName) {
                // Check if the policy name is unique
                const isUnique = await checkPolicyNameUnique(trimmedPolicyName);
                if (!isUnique) {
                    methods.setError("policyName", {
                        type: "manual",
                        message: "A policy with this name already exists, please choose a different name.",
                    });
                    setIsLoading(false);
                    return;
                }
            }

            // Proceed with submission if the name is unique or unchanged
            const transformedData = {
                Attributes: {
                    policyName: trimmedPolicyName,
                    action: data.action,
                    description: data.description,
                    ...(selectedPolicy ? {} : { conditions: {} }),
                },
            };

            const endpoint = selectedPolicy
                ? `/api/policy?action=update&EntityId=${selectedPolicy.id}`
                : "/api/policy?action=create";

            const response = await api.post(endpoint, transformedData, { withCredentials: true });

            if (response.status === 201 || response.status === 200) {
                console.log("Policy created/updated:", response.data);
                setIsFormDirty(false); // Reset dirty state
                onSubmit(); // Refresh the list or perform other actions
            } else {
                console.error("Error creating/updating policy:", response.data);
            }
        } catch (error) {
            console.error("Error during form submission:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const actionOptions = [
        { value: "ALLOW", label: "ALLOW" },
        { value: "BLOCK", label: "BLOCK" },
        { value: "ISOLATE", label: "ISOLATE" },
        { value: "WHITELIST", label: "WHITELIST" },
        { value: "ALERT", label: "ALERT" },
        { value: "USER ALERT", label: "USER ALERT" },
    ];

    const checkPolicyNameUnique = async (policyName) => {
        try {
            const response = await api.get(`/api/policy?action=check-name&name=${encodeURIComponent(policyName)}`);
            return response.data.isUnique;
        } catch (error) {
            console.error("Error checking policy name uniqueness:", error);
            return false; // Treat errors as the name not being unique
        }
    };


    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
                <Typography variant="h5" component="h1" gutterBottom>
                    {selectedPolicy ? "Edit Policy" : "Add Your New Policy Here"}
                </Typography>

                <Controller
                    name="policyName"
                    control={control}
                    rules={{ required: "Policy name is required" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Policy Name"
                            variant="outlined"
                            margin="normal"
                            placeholder="e.g., Block all traffic"
                            error={!!errors.policyName}
                            helperText={errors.policyName?.message}
                        />
                    )}
                />

                <Controller
                    name="action"
                    control={control}
                    rules={{ required: "Action is required" }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            select
                            label="Action"
                            variant="outlined"
                            margin="normal"
                            error={!!errors.action}
                            helperText={errors.action?.message}
                        >
                            {actionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            label="Description"
                            multiline
                            rows={3}
                            variant="outlined"
                            margin="normal"
                            placeholder="Enter a description"
                        />
                    )}
                />

                {/* Submit button */}
                <Box display="flex" justifyContent="center" marginTop={4}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isLoading || !isValid || !isFormDirty}  // Disable button while loading or if form is not valid or if form is dirty
                        sx={{ width: '150px', position: 'relative' }}
                    >
                        {isLoading ? (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'white',
                                    position: 'absolute',
                                }}
                            />
                        ) : "Save"}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default PolicyForm;