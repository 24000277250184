import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Dashboard from "./scenes/dashboard";
import Policy from "./scenes/policy";
import LogChart from "./scenes/logs";
import Settings from "./scenes/settings";
import Devices from "./scenes/devices";
import DataExchange from "./scenes/dataExchange";
import Dlp from "./scenes/dlp";
import DLPProfileConfig from "./scenes/dlp/DLPProfileConfig";
import FileProtection from "./scenes/fileProtection";
import TlsInspection from "./scenes/TlsInspection";
import Network from "./scenes/network";
import Domain from "./scenes/domain";
import ApiSecurity from "./scenes/ApiSecurity";
import Users from "./scenes/users";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import PolicyConfig from "./scenes/policy/PolicyConfig";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { AuthProvider } from './components/auth/AuthContext.js';
import { ChangesProvider } from "./scenes/TlsInspection/ChangesContext.js";
import PrivateRoute from './components/auth/PrivateRoute';

function App() {
  const [theme, colorMode] = useMode('light');

  return (
    <ChangesProvider>
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Routes>
            <Route path='/' element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Layout><Dashboard /></Layout>} />} />
            <Route path="/dashboard/policy" element={<PrivateRoute element={<Layout><Policy /></Layout>} />} />
            <Route path="/dashboard/tls-inspection" element={<PrivateRoute element={<Layout><TlsInspection /></Layout>} />} />
            <Route path="/dashboard/logs" element={<PrivateRoute element={<Layout><LogChart /></Layout>} />} />
            <Route path="/dashboard/policy/rule" element={<PrivateRoute element={<Layout><PolicyConfig /></Layout>} />} />
            <Route path="/dashboard/devices" element={<PrivateRoute element={<Layout><Devices /></Layout>} />} />
            <Route path="/dashboard/network" element={<PrivateRoute element={<Layout><Network /></Layout>} />} />
            <Route path="/dashboard/domain" element={<PrivateRoute element={<Layout><Domain /></Layout>} />} />
            <Route path="/dashboard/data-exchange" element={<PrivateRoute element={<Layout><DataExchange /></Layout>} />} />
            <Route path="/dashboard/dlp" element={<PrivateRoute element={<Layout><Dlp /></Layout>} />} />
            <Route path="/dashboard/dlp/profile/configure" element={<PrivateRoute element={<Layout><DLPProfileConfig /></Layout>} />} />
            <Route path="/dashboard/file-protection" element={<PrivateRoute element={<Layout><FileProtection /></Layout>} />} />
            <Route path="/dashboard/users" element={<PrivateRoute element={<Layout><Users /></Layout>} />} />
            <Route path="/dashboard/settings" element={<PrivateRoute element={<Layout><Settings /></Layout>} />} />
            <Route path="/dashboard/api-security" element={<PrivateRoute element={<Layout><ApiSecurity /></Layout>} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {/* Other routes */}
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
    </AuthProvider>
    </ChangesProvider>
  );
}

export default App;