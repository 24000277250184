import React from 'react';
import { Drawer, Button, Typography, List, ListItem, ListItemText, Divider, IconButton, Box, Chip } from '@mui/material';
import { ChevronRight, Close, Security, AccessTime, Category, Info, ContentCopy } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { tokens } from '../../theme';
import { format } from "date-fns";

const DrawerConfig = ({ open, onClose, selectedPolicy }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  if (!selectedPolicy) return null;

  const {
    PolicyID = 'Default Policy ID',
    name = 'Default Policy',
    description = 'This policy helps manage security settings.',
  } = selectedPolicy || {};

  const handleCopyPolicyId = () => {
    navigator.clipboard.writeText(PolicyID).then(() => {
      alert('Policy ID copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy policy ID: ', err);
    });
  };

  const openPolicyConfig = () => {
    navigate('/dashboard/policy/rule', { state: { policy: selectedPolicy } });
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box className="w-80 p-6">
        <Box className="flex justify-between items-center mb-4">
          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', color: colors.greenAccent[400] }}>
            <Security sx={{ marginRight: 1 }} />
            {selectedPolicy.PolicyName}
          </Typography>
          <IconButton onClick={onClose} size="small" sx={{ color: colors.grey[100] }}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box className="text-center">
          <List disablePadding>
            <ListItem>
              <ListItemText primary="Policy ID" secondary={PolicyID} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <IconButton onClick={handleCopyPolicyId} size="small" sx={{ color: colors.grey[500] }}>
                <ContentCopy />
              </IconButton>
            </ListItem>
            <ListItem>
              <ListItemText primary="Policy Action" secondary={selectedPolicy.action} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <Category sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Last Edited" secondary={selectedPolicy.lastEdited} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <AccessTime sx={{ color: colors.grey[500] }} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Description" secondary={description} primaryTypographyProps={{ fontWeight: 'bold' }} secondaryTypographyProps={{ color: colors.grey[200] }} />
              <Info sx={{ color: colors.grey[500] }} />
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            endIcon={<ChevronRight />}
            fullWidth
            onClick={openPolicyConfig}
            color="secondary"
          >Configure</Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerConfig;