import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  Box,
  Chip,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  Help as HelpIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import api from '../../api/api';

const fetchFileProfiles = async () => {
  try {
    const response = await api.get('/file/profiles?action=read', { withCredentials: true });
    return response.data.map(profile => ({
      id: profile.EntityId,
      name: profile.Attributes.profile_name,
      fileNames: profile.Attributes.file_names,
      fileTypes: profile.Attributes.file_types,
      fileHash: profile.Attributes.file_hash,
      objectId: profile.Attributes.object_id,
      fileSize: profile.Attributes.file_size,
      protectedEncrypted: profile.Attributes.protected_encrypted,
      updatedAt: profile.updatedAt,
    }));
  } catch (error) {
    console.error('Error fetching file profiles:', error);
    return [];
  }
};

const fetchDLPProfile = async (profileId) => {
  try {
    const response = await api.get(`/dlp/profiles?action=read&id=${profileId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching DLP profile:', error);
    return null;
  }
};

export default function DLPProfileConfig() {
  const location = useLocation();
  const navigate = useNavigate();
  const { profile } = location.state || {};

  const isPredefined = profile?.type === 'Predefined';

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: profile?.name || '',
    description: profile?.description || '',
    fileAttributes: profile?.fileProfiles || [],
    detectionRule: profile?.detectionRule || '',
    action: profile?.action || 'log',
  });
  const [initialFormData, setInitialFormData] = useState({ ...formData });
  const [hasChanges, setHasChanges] = useState(false);
  const [fileProfiles, setFileProfiles] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    const isEqual = JSON.stringify(initialFormData) === JSON.stringify(formData);
    setHasChanges(!isEqual);
  }, [formData, initialFormData]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const profiles = await fetchFileProfiles();
      setFileProfiles(profiles);

      if (profile?.id) {
        const dlpProfile = await fetchDLPProfile(profile.id);
        if (dlpProfile) {
          const loadedData = {
            name: dlpProfile.Attributes?.profileName || '',
            description: dlpProfile.Attributes?.description || '',
            action: dlpProfile.Attributes?.action || 'log',
            fileAttributes: dlpProfile.Attributes?.fileAttributes || [],
            detectionRule: dlpProfile.Attributes?.customRegex || '',
          };
          setFormData(loadedData);
          setInitialFormData(loadedData);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [profile?.id]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value ?? '',
    }));
  }, []);

  const handleProfileChange = useCallback((event) => {
    setFormData(prevData => ({
      ...prevData,
      fileAttributes: event.target.value, // Store selected file attributes EntityIds
    }));
  }, []);

  const handleSave = useCallback(async () => {
    const updatedProfileData = {
      EntityId: profile?.id,
      Attributes: {
        profileName: formData.name,
        description: formData.description,
        action: formData.action,
        fileAttributes: formData.fileAttributes,
        customRegex: formData.detectionRule,
      },
    };

    setIsSaving(true);
    try {
      const response = profile?.id
        ? await api.put(`/dlp/profiles?action=update&EntityId=${profile.id}`, updatedProfileData, {
          headers: { 'Content-Type': 'application/json' },
        })
        : await api.post(`/dlp/profiles?action=create`, updatedProfileData, {
          headers: { 'Content-Type': 'application/json' },
        });

      if (response.status === 200 || response.status === 201) {
        navigate('/dashboard/dlp');
      } else {
        console.error('Failed to save profile:', response.data);
      }
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsSaving(false);
    }
  }, [formData, profile, navigate]);


  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const handleCancel = useCallback(() => {
    setOpenConfirmModal(true);
  }, []);

  const handleConfirmClose = useCallback(() => {
    setOpenConfirmModal(false);
    navigate(`/dashboard/dlp`);
  }, [navigate]);

  const handleCancelClose = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);

  const steps = [
    {
      label: 'Basic Information',
      content: (
        <>
          <TextField
            label="Profile Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            className="mb-4"
            disabled={isPredefined}
            required
          />
          <div className="mb-4"></div>
          <TextField
            label="Description"
            name="description"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleInputChange}
            fullWidth
            className="mb-4"
            disabled={isPredefined}
          />
        </>
      ),
    },
    {
      label: 'File Profiles',
      content: (
        <FormControl fullWidth className="mb-4">
          <InputLabel id="file-profiles-label">File Profiles</InputLabel>
          <Select
            labelId="file-profiles-label"
            multiple
            value={formData.fileAttributes}
            onChange={handleProfileChange}
            renderValue={(selected) => (
              <div className="flex flex-wrap gap-1">
                {selected.map((id) => (
                  <Chip key={id} label={fileProfiles.find(fp => fp.id === id)?.name} />
                ))}
              </div>
            )}
          >
            {loading ? (
              <MenuItem disabled>
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} className="mr-2" />
                  <Typography>Loading file profiles...</Typography>
                </Box>
              </MenuItem>
            ) : (
              fileProfiles.map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  <Checkbox checked={formData.fileAttributes.indexOf(profile.id) > -1} />
                  <Typography>{profile.name}</Typography>
                  <Tooltip title={profile.description}>
                    <HelpIcon fontSize="small" className="ml-1" />
                  </Tooltip>
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      ),
    },
    {
      label: 'Action on Detection',
      content: (
        <FormControl fullWidth className="mb-4">
          <InputLabel id="action-label">Action on Detection</InputLabel>
          <Select
            labelId="action-label"
            name="action"
            value={formData.action}
            onChange={handleInputChange}
          >
            <MenuItem value="allow">Allow</MenuItem>
            <MenuItem value="block">Block</MenuItem>
            <MenuItem value="encrypt">Encrypt</MenuItem>
            <MenuItem value="quarantine">Quarantine</MenuItem>
            <MenuItem value="user-alert">User-Alert</MenuItem>
            <MenuItem value="alert">Alert</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      label: 'Advanced Options',
      content: (
        <TextField
          label="Custom Detection Rule (RegEx)"
          name="detectionRule"
          value={formData.detectionRule}
          onChange={handleInputChange}
          fullWidth
          className="mb-4"
          helperText="Optional: Enter a custom regular expression to detect sensitive data not covered by the selected file profiles"
        />
      ),
    },
  ];
  

  if (!profile) {
    return (
      <div className="p-8 text-center">
        <Typography variant="h6">No profile found. Please return to the DLP list.</Typography>
        <Button variant="contained" onClick={() => navigate('/dashboard/dlp')} className="mt-4">
          Go Back
        </Button>
      </div>
    );
  }

  return (
    <Box mb={4} p={8} borderRadius="0.5rem" boxShadow={3}>
      <div className="flex items-center mb-4">
        <IconButton onClick={handleExpand} aria-label="expand">
          <ExpandMoreIcon className={expanded ? 'rotate-180' : 'rotate-0'} />
        </IconButton>
        <Typography variant="h4">Configure DLP Profile</Typography>
      </div>

      <Collapse in={expanded}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
  <div className="mb-4">
    {step.content}
    <div className="mt-4">
      {index !== steps.length - 1 && ( // Remove the Save button for the last step
        <Button
          variant="contained"
          onClick={handleNext}
          className="mr-2"
        >
          Continue
        </Button>
      )}
      <Button disabled={index === 0} onClick={handleBack}>
        Back
      </Button>
    </div>
  </div>
</StepContent>               
            </Step>
          ))}
        </Stepper>
      </Collapse>

      <div className="flex justify-between mt-8">
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleCancel}
        >
          Back to DLP List
        </Button>
        <Button
  variant="contained"
  color="primary"
  startIcon={<SaveIcon />}
  onClick={handleSave}
  disabled={!hasChanges || isSaving} // Disabled if no changes or saving
>
  {isSaving ? <CircularProgress size={24} color="inherit" /> : 'Save Profile'}
</Button>
      </div>
      <Dialog open={openConfirmModal} onClose={handleCancelClose}>
        <DialogTitle>Cancel Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure to cancel? Any unsaved configurations will be lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} variant="contained">Yes</Button>
          <Button onClick={handleCancelClose} variant="outlined" autoFocus>No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}